import React from 'react';
import Layout from '../components/Layout';


const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <h1>Not found</h1>
  </Layout>
);

export default NotFoundPage;
